import { invokeApi } from "../../utils";

export const HandleStripePay = async (userEmail, user_id, source) => {
  const data = {admin_user_id: user_id, source: source};
  const requestObj = {
    path: '/pay-by-stripe',
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const HandleStripeSuccess = async (userId) => {
  const data = {user_id: userId};
  const requestObj = {
    path: '/stripe-pay-success',
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
