import React, { useEffect, useState } from "react";
import { AddUserData } from "../../DAL/Users/Users";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import LoaderModal from "../../components/LoaderModel";

export default function AddUser() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    full_name: "",
    email: "",
    password: "",
    user_type: "user",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputs?.password.length < 8) {
      enqueueSnackbar("Password contains min 8 characters", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    const data = {
      email: inputs?.email,
      password: inputs?.password,
      full_name: inputs?.full_name,
      user_type: inputs?.user_type,
    };
    const resp = await AddUserData(userId, data);
    if (resp?.status == 200) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/user-list");
    } else {
      setLoading(false);
      enqueueSnackbar(resp?.message, { variant: "success" });
    }
  };

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    setUserId(UserData?.user_id);
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <LoaderModal open={loading} />
      <div>
        <Card
          sx={{
            padding: "10px",
            width: "40%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "8%",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Iconify
              icon={"ri:arrow-left-line"}
              width={25}
              height={25}
              sx={{ cursor: "pointer", marginLeft: "2rem" }}
              onClick={() => navigate("/user-list")}
            />
            <div className="container row m-auto">
              <div>
                <h5 className="text-center">Add User</h5>
              </div>
              <div className="col-lg-12 mt-3">
                <TextField
                  label="Full Name"
                  required
                  onChange={handleChange}
                  name="full_name"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="col-lg-12 mt-3">
                <TextField
                  label="Email"
                  required
                  onChange={handleChange}
                  type="email"
                  name="email"
                  fullWidth
                  size="small"
                />
              </div>

              <div className="col-lg-12 mt-3">
                <TextField
                  size="small"
                  fullWidth
                  required
                  label="Password"
                  onChange={handleChange}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-lg-12 mt-3">
                <Button
                  className="active w-100 mb-3"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}
