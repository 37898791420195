import React, { useEffect, useState } from "react";
import Iconify from "../../components/Iconify";
import { TextField } from "@mui/material";
import { AddQuestionData } from "../../DAL/Question/Question";
import { useSnackbar } from "notistack";
import LoaderModal from "../../components/LoaderModel";

export default function Question({ uuid }) {
  const [question, setQuestion] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await AddQuestionData(uuid, question);
    if (resp?.status == 200) {
      enqueueSnackbar("Response generated successfully.", {
        variant: "success",
      });
      setAnswer(resp);
      setLoading(false);
    } else {
      enqueueSnackbar("Question not submit", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <LoaderModal open={loading} />
      <div className="mt-4 ">
        <TextField
          fullWidth
          multiline
          rows={8}
          name="question"
          onChange={(e) => setQuestion(e.target.value)}
          // placeholder="Ask Anything"
          sx={{
            // Custom styling for placeholder
            "& .MuiInputBase-input::placeholder": {
              color: "silver", // Customize placeholder color
              opacity: 1, // Override default opacity if needed
              fontSize: "20px",
            },
            // Remove blue shadow on focus
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "silver", // Default border color
                border: "1px solid silver",
                borderRadius: "22px",
              },
              "&:hover fieldset": {
                borderColor: "silver", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "silver", // Border color when focused
                boxShadow: "none", // Remove the shadow
              },
            },
          }}
        />
        <div className="text-end mt-3">
          <button className="active" onClick={handleAddData}>
            Submit
          </button>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <TextField
          fullWidth
          multiline
          disabled
          rows={5}
          value={answer?.answer}
          label=""
          sx={{
            // Custom styling for placeholder
            "& .MuiInputBase-input::placeholder": {
              color: "black", // Customize placeholder color
              opacity: 1, // Override default opacity if needed
              fontSize: "20px",
            },
            // Remove blue shadow on focus
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "silver", // Default border color
                border: "1px solid",
                borderRadius: "22px",
              },
              "&:hover fieldset": {
                borderColor: "silver", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "silver", // Border color when focused
                boxShadow: "none", // Remove the shadow
              },
            },
          }}
        />
      </div>
    </>
  );
}
