import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate } from "react-router-dom";
import LoaderModal from "../../components/LoaderModel";
import { useSnackbar } from "notistack";
import { FetchOldRecording } from "../../DAL/Zoom/Zoom";


export default function FetchOldRecordingsModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState("");
  const [openLoaderModel, setOpenLoaderModel] = useState(false);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({start_date: "", end_date: ""});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpenLoaderModel(true);
    const data = {
      start_date: inputs?.start_date,
      end_date: inputs?.end_date,
      user_id: `${userId}`
    };

    const resp = await FetchOldRecording(data);
    if (resp?.status == 200) {
      setOpenLoaderModel(false);
      setOpen(false);
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/user-list");
    } else {
      setOpenLoaderModel(false);
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
};

useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    setUserId(UserData?.user_id);
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
}, []);

  return (
    <div>
      <LoaderModal open={openLoaderModel} />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head d-flex justify-content-between p-2 bg-dark text-white">
            <p>Seed Old Recordings</p>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="card-main mt-2 ">
            <div className="text-end mb-2">
                <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row m-auto">
                        <div className="col-lg-3 mt-4"> Start Date: </div>
                        <div className="col-lg-9 mt-3">
                            <TextField
                            onChange={handleChange}
                            type="date"
                            name="start_date"
                            required
                            fullWidth
                            size="small"
                            />
                        </div>
                    </div>
                <div className="row m-auto">
                    <div className="col-lg-3 mt-4"> End Date: </div>
                    <div className="col-lg-9 mt-3">
                        <TextField
                        onChange={handleChange}
                        name="end_date"
                        type="date"
                        fullWidth
                        required
                        size="small"
                        />
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <Button
                    className="active w-100 mb-3"
                    type="submit"
                    variant="contained"
                    >
                    Submit
                    </Button>
                </div>
                </div>
                </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
