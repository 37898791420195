import React from "react";

export default function GapAnalysis({ data }) {
  return (
    <>
      <div className="mt-4">
        <h6>
          <b>Where are they today?</b>
        </h6>
        <p>
          <span className="fw-bold">Answer:</span> {data?.today_status}
        </p>
      </div>
      <div className="mt-4">
        <h6>
          <b>What is the ideal future state?</b>
        </h6>
        <p>
          <span className="fw-bold">Answer:</span> {data?.future_goal}
        </p>
      </div>
      <div className="mt-4 mb-4">
        <h6>
          <b>
            What’s preventing them from moving where they are today, to their
            ideal future state?
          </b>
        </h6>
        <p>
          <span className="fw-bold">Answer:</span>{" "}
          {data?.point_of_preventations}
        </p>
      </div>
    </>
  );
}
