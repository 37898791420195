import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  TextField,
  Typography,
  CssBaseline,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import QuestionAnswer from "./QuestionAnswer";
import {
  AddInsightChatQuestion,
  DeleteOldQuestion,
  OldChatQuestion,
} from "../../DAL/Chats/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import LoaderModal from "../../components/LoaderModel";

const drawerWidth = 280; // Width of the Drawer
const transitionDuration = 200; // Drawer open/close speed
const appBarHeight = 54; // Height of the AppBar

const UserInsights = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [question, setQuestion] = useState("");
  const [oldQuestions, setOldQuestions] = useState([]);
  const [value, setValue] = useState("");
  const [chatId, setChatId] = useState("");
  const [openLoaderModel, setOpenLoaderModel] = useState(false);

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const getOldChatList = async () => {
    setOpenLoaderModel(true);
    const resp = await OldChatQuestion(state);
    if (resp?.status == 200) {
      setOpenLoaderModel(false);
      setOldQuestions(resp?.previous_chats);
    }
  };

  const handleDeleteQuestion = async (id) => {
    const resp = await DeleteOldQuestion(id);
    if (resp?.status == 200) {
      enqueueSnackbar("Delete successfully", { variant: "success" });
      getOldChatList();
    } else {
      enqueueSnackbar("Question not delete", {
        variant: "error",
      });
    }
  };

  const handleSubmit = async (e, val) => {
    e.preventDefault();
    setOpenLoaderModel(true);
    const formData = new FormData();
    formData.append("question", val ? val?.chat_title : question);
    formData.append("chat_uuid", val ? val.chat_uuid : "");
    formData.append("user_id", state);

    const resp = await AddInsightChatQuestion(formData);

    if (resp?.status == 200) {
      setChatId(resp?.chat_uuid);
      enqueueSnackbar("Question send successfully", { variant: "success" });
      setQuestion("");
      setOpenLoaderModel(false);
      setValue(1);
    } else {
      enqueueSnackbar("Question not send", { variant: "error" });
      setOpenLoaderModel(false);
    }
  };

  useEffect(() => {
    getOldChatList();
  }, []);

  return (
    <div>
      <CssBaseline />
      <LoaderModal open={openLoaderModel} />
      {/* AppBar with sliding background color */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: open ? "#EAF3FD" : "white",
          color: "black",
          backgroundPosition: open ? "left" : "left",
          backgroundSize: `${drawerWidth}px `,
          transition: `background-size ${transitionDuration}ms ease`, // Transition for the sliding effect
          width: open ? `${drawerWidth}px` : `${drawerWidth}px`,
          left: 0,

          boxShadow: "none", // Remove shadow
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex align-items-center">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer(!open)}
              sx={{ zIndex: 1201 }} // Ensures the button stays above the drawer
            >
              {open ? (
                <img src="close.png" alt="" />
              ) : (
                <img src="open.png" alt="" />
              )}
            </IconButton>
            <Typography variant="h5">Ohshi</Typography>
          </div>
          <IconButton>
            <img src="refresh.png" alt="" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (Slider) - Opens under the AppBar */}
      <Drawer
        anchor="left"
        open={open}
        variant="persistent"
        transitionDuration={transitionDuration}
        PaperProps={{
          sx: {
            top: appBarHeight, // Adjust to be below the AppBar (AppBar height is 64px)
            backgroundColor: "#EAF3FD",
            height: `calc(100% - ${appBarHeight}px)`, // Full height below the AppBar
            overflowY: "scroll",
            scrollbarWidth: "none",
            borderRight: "none", // Remove right-side border
          },
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ width: drawerWidth, marginTop: "2rem" }} role="presentation">
          {oldQuestions?.map((val) => (
            <>
              <div className=" oldChat mt-3 ">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography
                    variant="body2"
                    onClick={(e) => handleSubmit(e, val)}
                    sx={{ cursor: "pointer" }}
                  >
                    {val?.chat_title}
                  </Typography>
                  <IconButton>
                    <DeleteIcon
                      className="delIcon"
                      onClick={() => handleDeleteQuestion(val?.chat_uuid)}
                    />
                  </IconButton>
                </div>
              </div>
            </>
          ))}
        </Box>
      </Drawer>

      {/* Main content on the right side */}
      <Box
        sx={{
          marginLeft: open ? `${drawerWidth}px` : "0px", // Adjust margin when Drawer is open
          transition: `margin-left ${transitionDuration}ms ease`, // Smooth transition for content shift
          padding: "16px", // Add padding to main content
          marginTop: `${appBarHeight}px`, // Add margin to respect AppBar height
        }}
      >
        {/* Centered TextField and Typography */}
        {value == 1 ? (
          <QuestionAnswer chatuuid={chatId} userId={state} />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh", // Adjust height as per requirement
              }}
              className="Container m-auto"
            >
              <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Ask me a question to generate insights
              </Typography>
              <TextField
                placeholder="Message Oshish"
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px", // Make sure to target the input element for border radius
                  },
                  width: "100%", // Increase the width of the TextField (adjust as needed)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="Group.png" alt="" style={{ height: "20px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <img
                          src="arrow.png"
                          alt=""
                          style={{ height: "33px" }}
                          onClick={handleSubmit}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ fontSize: "13px", marginTop: "20px" }}>
                By messaging <strong style={{ color: "black" }}>Ohshi</strong>,
                you agree to our
                <strong style={{ textDecoration: "underline", color: "black" }}>
                  Terms
                </strong>
                and have read our
                <strong style={{ textDecoration: "underline", color: "black" }}>
                  Privacy Policy.
                </strong>
              </FormHelperText>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default UserInsights;
