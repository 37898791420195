import { invokeApi } from "../../utils";

export const AddQuestionData = async (id, question) => {
  const data = {
    question: question,
    meeting_uuid: id,
  };
  const requestObj = {
    path: `/chat-with-text`,
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
