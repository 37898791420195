import { invokeApi } from "../../utils";

export const RegisterData = async (data) => {
  const requestObj = {
    path: `/create-organization-with-admin-user`,
    method: "POST",
    postData: data,
  };

  return invokeApi(requestObj);
};

export const LoginData = async (data) => {
  const requestObj = {
    path: `/sign-in`,
    method: "POST",
    headers: {},
    postData: data,
  };

  return invokeApi(requestObj);
};
