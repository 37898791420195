import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Iconify from "../../components/Iconify";
import BarChart from "./Bars";
import { useNavigate, useParams } from "react-router-dom";
import Question from "../AskQuestion/Question";
import {
  AddToFavouriteRecording,
  AllRecordings,
  RemoveToFavouriteRecording,
  SingleRecording,
  DeleteRecording,
} from "../../DAL/Recordings/Recording";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../Hooks/ContextApi";
import LoaderModal from "../../components/LoaderModel";
import Meddpicc from "./MEDDPICC";
import Bant from "./BANT";
import GapAnalysis from "./GapAnalysis";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  borderRadius: "10px",
  //   "&.Mui-focused": { width: 320 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `#001f3f33 !important`,
  },
  "& legend": {
    display: "none",
  },
}));

export default function Detail() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [val, setVal] = useState("highlight");
  const { _set_data } = useAppContext();
  // Play or pause the video
  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const getAllRecordings = async (id, data) => {
    setLoading(true);
    const resp = await AllRecordings(id, "personal", data);
    if (resp?.status == 200) {
      _set_data(resp);
      navigate("/home");
      setLoading(false);
    }
  };

  const getCallDetail = async () => {
    setLoading(true);
    const resp = await SingleRecording(id);
    if (resp?.status == 200) {
      setData(resp);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setTimeout(() => {
      getAllRecordings(userId, e.target.value);
    }, 3000);
    setSearchText(e.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(data?.share_url)
      .then(() => {
        enqueueSnackbar("Copied successfully", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
  };

  const handleDelete = async () => {
    setLoading(true);
    let resp = await DeleteRecording(userId, data?.meeting_uuid);
    if (resp?.status == 200) {
      enqueueSnackbar(resp.message, { variant: "success" });
      navigate('/home');
    } else {
      enqueueSnackbar(resp?.status, { variant: "error" });
      setLoading(false);
    }
  }

  const handleClick = async () => {
    setLoading(true);
    let resp = null;
    let message = null;
    if (data?.is_favourite) {
      message = "Successfully removed from favourite list.";
      resp = await RemoveToFavouriteRecording(userId, data?.meeting_uuid);
    } else {
      message = "Successfully added to favourite list.";
      resp = await AddToFavouriteRecording(userId, data?.meeting_uuid);
    }
    if (resp?.status == 200) {
      enqueueSnackbar(message, { variant: "success" });
      getCallDetail();
    } else {
      enqueueSnackbar(resp?.status, { variant: "error" });
      setLoading(false);
    }
  };

  // Toggle full screen
  const handleFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  // Change playback speed
  const handleSpeedChange = (speed) => {
    setPlaybackRate(speed);
    videoRef.current.playbackRate = speed;
  };

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    setUserId(UserData?.user_id);
    setIsAdmin(UserData?.role == 'Admin');
    getCallDetail();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <LoaderModal open={loading} />
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-12 d-flex flex-wrap justify-content-between">
              <Typography
                variant="h6"
                onClick={() => navigate("/home")}
                sx={{ cursor: "pointer" }}
              >
                <Iconify
                  icon={"si:arrow-left-duotone"}
                  sx={{ width: 30, height: 30 }}
                />{" "}
                Back to all calls
              </Typography>
              <SearchStyle
                style={{ height: "2.5rem", paddingBottom: "5px" }}
                placeholder="Search here"
                onChange={handleSearch}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "#001f3f", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
              />
            </div>
            <div className="col-lg-12 mt-3">
              <Typography variant="h5" className="fw-bold">
                {data?.topic}
              </Typography>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="">
                <Typography variant="subtitle1" className="fw-bold">
                  <CalendarMonthIcon /> {data?.start_date}
                </Typography>
                <Typography variant="subtitle1" className="fw-bold">
                  <WatchLaterIcon /> {data?.call_length}
                </Typography>
              </div>
              <div>
                <Question uuid={data?.meeting_uuid} />
              </div>
            </div>

            {/* right side */}
            <div className="col-lg-7">
              <div className="d-flex gap-3 justify-content-end">
                <Button
                  sx={{ cursor: "pointer" }}
                  className="active"
                  onClick={handleClick}
                >
                  {data?.is_favourite
                    ? "Remove from Favourites"
                    : "Add to Favorites"}
                </Button>
                <Button
                  sx={{ cursor: "pointer" }}
                  className="active"
                  onClick={handleCopy}
                >
                  Share Call
                </Button>
                {isAdmin && <Button
                  sx={{ cursor: "pointer" }}
                  className="active"
                  onClick={handleDelete}
                >
                  Delete
                </Button>}
              </div>
              <div className="mt-4">
                <video
                  ref={videoRef}
                  // className="video-element"
                  width="100%"
                  style={{ borderRadius: "20px" }}
                  controls={true}
                  playbackRate={playbackRate}
                >
                  {data && <source src={data.play_url} type="video/mp4" />}
                  Your browser does not support the video tag.
                </video>
                {/* <div className="controls mt-3">
                  <div className="playback-speed">
                    <Iconify
                      sx={{ cursor: "pointer" }}
                      icon={"solar:alt-arrow-up-bold"}
                      onClick={() => handleSpeedChange(playbackRate + 0.25)}
                    />
                    <span>{playbackRate}x</span>
                    <Iconify
                      sx={{ cursor: "pointer" }}
                      icon={"iconamoon:arrow-down-2-fill"}
                      onClick={() => handleSpeedChange(playbackRate - 0.25)}
                      disabled={playbackRate <= 0.5}
                    />
                  </div>
                  <div className="d-flex gap-3">
                    <p
                      style={{ cursor: "pointer" }}
                      className="rewind fw-bold"
                      onClick={() => (videoRef.current.currentTime -= 15)}
                    >
                      15s -
                    </p>
                    <Iconify
                      sx={{ cursor: "pointer" }}
                      icon={
                        isPlaying ? "carbon:pause-outline" : "bi:play-circle"
                      }
                      onClick={togglePlayPause}
                      width={30}
                      height={30}
                    />

                    <p
                      style={{ cursor: "pointer" }}
                      className="forward fw-bold"
                      onClick={() => (videoRef.current.currentTime += 15)}
                    >
                      15s +
                    </p>
                  </div>
                  <Iconify
                    sx={{ cursor: "pointer" }}
                    icon={
                      videoRef.current?.muted
                        ? "ion:volume-mute-outline"
                        : "codicon:unmute"
                    }
                    className="mute-unmute"
                    onClick={() =>
                      (videoRef.current.muted = !videoRef.current.muted)
                    }
                    width={30}
                    height={30}
                  />

                  <p
                    style={{ cursor: "pointer" }}
                    className="fullscreen fw-bold"
                    onClick={handleFullScreen}
                  >
                    Full Screen
                  </p>
                </div> */}
              </div>
              <div>
                <BarChart data={data?.participants} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-wrap gap-4 mt-4">
                <Typography
                  variant="h6"
                  className={
                    val == "highlight" ? "activePoint" : "text-secondary"
                  }
                  onClick={() => setVal("highlight")}
                  sx={{ cursor: "pointer" }}
                >
                  Highlights
                </Typography>
                <Typography
                  variant="h6"
                  className={
                    val == "MEDDPICC" ? "activePoint" : "text-secondary"
                  }
                  onClick={() => setVal("MEDDPICC")}
                  sx={{ cursor: "pointer" }}
                >
                  MEDDPICC
                </Typography>
                <Typography
                  variant="h6"
                  className={val == "BANT" ? "activePoint" : "text-secondary"}
                  onClick={() => setVal("BANT")}
                  sx={{ cursor: "pointer" }}
                >
                  BANT
                </Typography>
                <Typography
                  variant="h6"
                  className={val == "GAP" ? "activePoint" : "text-secondary"}
                  onClick={() => setVal("GAP")}
                  sx={{ cursor: "pointer" }}
                >
                  Gap Analysis
                </Typography>
              </div>
              <hr />
              <div className="col-lg-6">
                {val == "highlight" ? (
                  <>
                    <div className="mt-4">
                      <Typography variant="h5" className="fw-bold">
                        Key Points
                      </Typography>
                      <ul className="mt-3">
                        <li style={{ listStyle: "none" }}>
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {data?.key_points}
                          </Typography>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <Typography variant="h5" className="fw-bold">
                        Next Steps
                      </Typography>
                      <ul className="mt-3">
                        <li style={{ listStyle: "none" }}>
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {data?.next_steps}
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : val == "MEDDPICC" ? (
                  <Meddpicc data={data} />
                ) : val == "BANT" ? (
                  <Bant data={data} />
                ) : val == "GAP" ? (
                  <GapAnalysis data={data} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
