import { LinearProgress } from "@mui/material";
import React from "react";

export default function Meddpicc({ data }) {
  console.log(data, "dhsjfgjkdhskjfhkl");
  return (
    <>
      <div className="mt-5">
        <h6>Metrics : {data?.metrics}</h6>
        <p>{data?.metrics_text}</p>
        <LinearProgress
          variant="determinate"
          value={data?.metrics}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Economic Buyer : {data?.economic_buyer}</h6>
        <p>{data?.economic_buyer_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.economic_buyer / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Decision Process : {data?.decision_process}</h6>
        <p>{data?.decision_process_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.decision_process / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Paper Process : {data?.paper_process}</h6>
        <p>{data?.paper_process_text}</p>
        <LinearProgress
          variant="determinate"
          value={data?.paper_process}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Identified Pain : {data?.identified_pain}</h6>
        <p>{data?.identified_pain_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.identified_pain / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Champion : {data?.champion}</h6>
        <p>{data?.champion_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.champion / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5 mb-4">
        <h6>Competition : {data?.competition}</h6>
        <p>{data?.competition_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.competition / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
    </>
  );
}
