import { LinearProgress } from "@mui/material";
import React from "react";

export default function Bant({ data }) {
  return (
    <>
      <div className="mt-5">
        <h6>Budget : {data?.budget}</h6>
        <p>{data?.budget_text}</p>
        <LinearProgress
          variant="determinate"
          value={data?.budget}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Authority : {data?.authority}</h6>
        <p>{data?.authority_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.authority / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5">
        <h6>Need : {data?.need}</h6>
        <p>{data?.need_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.need / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
      <div className="mt-5 mb-4">
        <h6>Timeline : {data?.timeline}</h6>
        <p>{data?.timeline_text}</p>
        <LinearProgress
          variant="determinate"
          value={(data?.timeline / 100) * 100}
          sx={{
            height: 17, // Adjust height to match design
            borderRadius: 1,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E90FF", // Custom color for each participant
            },
          }}
        />
      </div>
    </>
  );
}
