import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/login";
import Register from "./Pages/Register/register";
import Home from "./Pages/HomePage/home";
import Detail from "./Pages/CallDetail/detail";
import UserList from "./Pages/UsersList/UserList";
import AddUser from "./Pages/UsersList/AddUser";
import UserInsights from "./Pages/UserInsights/UserInsights";

export default function Router() {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to={"/home"}></Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to={"/login"}></Navigate>;
    }
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route path="/call-detail/:id" element={<Detail />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/user-insights" element={<UserInsights />} />
      </Routes>
    </>
  );
}
