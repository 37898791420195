import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ZoomIntegration } from "../../DAL/Zoom/Zoom";
import { PayByStripe } from "../../DAL/Users/Users";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function StripeModal({ open, setOpen, role, paid, handleStripe }) {
  const [userId, setUserId] = React.useState("");

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user_data"));
    if (data) {
      setUserId(data?.user_id);
    }
  }, []);
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {role == "Admin" && paid == false ? (
            <>
              <div className="text-center">
                <Button
                  className=""
                  variant="contained"
                  onClick={handleStripe}
                >
                  Pay by stripe
                </Button>
              </div>
            </>
          ) : (
            <h6 className="mx-5">Ask your admin to pay by stripe</h6>
          )}
        </Box>
      </Modal>
    </div>
  );
}
