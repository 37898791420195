import React, { useEffect, useState } from "react";
import {
  AddInsightChatQuestion,
  GetQuestionAnswerList,
} from "../../DAL/Chats/Chat";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import LoaderModal from "../../components/LoaderModel";

export default function QuestionAnswer({ chatuuid, userId }) {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [question, setQuestion] = useState("");
  const [openLoaderModel, setOpenLoaderModel] = useState(false);

  const GetData = async () => {
    setOpenLoaderModel(true);
    const resp = await GetQuestionAnswerList(chatuuid);
    if (resp?.status == 200) {
      setData(resp?.qnas);
      setOpenLoaderModel(false);
    }
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    setOpenLoaderModel(true);
    const formData = new FormData();
    formData.append("question", question);
    formData.append("chat_uuid", chatuuid);
    formData.append("user_id", userId);

    const resp = await AddInsightChatQuestion(formData);

    if (resp?.status == 200) {
      enqueueSnackbar("Question send successfully", { variant: "success" });
      setQuestion("");
      setOpenLoaderModel(false);
      GetData();
    } else {
      enqueueSnackbar("Question not send successfully", { variant: "error" });
      setOpenLoaderModel(false);
    }
  };

  useEffect(() => {
    GetData();
  }, [chatuuid]);
  return (
    <>
      <LoaderModal open={openLoaderModel} />
      <div className="m-auto Container">
        <div
          style={{
            height: "73vh",
            overflowY: "scroll",
            scrollbarWidth: "none",
            marginBottom: "20px",
          }}
        >
          {data?.map((val) => (
            <>
              <Typography
                variant="body1"
                sx={{
                  border: "1px solid grey",
                  padding: "7px 20px",
                  borderRadius: "20px",
                  width: "fit-content",
                  marginLeft: "auto",
                }}
                className="fw-bold mt-3"
              >
                {val?.question}
              </Typography>

              <div className="mt-4">
                <Typography>{val?.answer}</Typography>
              </div>
            </>
          ))}
        </div>
        <div>
          <TextField
            placeholder="Message Oshish"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px", // Make sure to target the input element for border radius
              },
              width: "100%", // Increase the width of the TextField (adjust as needed)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="Group.png" alt="" style={{ height: "20px" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img
                      src="arrow.png"
                      alt=""
                      style={{ height: "33px" }}
                      onClick={handleQuestionSubmit}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText
            sx={{ fontSize: "13px", marginTop: "20px", textAlign: "center" }}
          >
            By messaging <strong style={{ color: "black" }}>Ohshi</strong>, you
            agree to our
            <strong style={{ textDecoration: "underline", color: "black" }}>
              Terms
            </strong>
            and have read our
            <strong style={{ textDecoration: "underline", color: "black" }}>
              Privacy Policy.
            </strong>
          </FormHelperText>
        </div>
      </div>
    </>
  );
}
