import { invokeApi } from "../../utils";

export const AllRecordings = async (userId, type, search) => {
  const requestObj = {
    path: `/all-recordings?user_id=${userId}&type=${type}&search_term=${search}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const SingleRecording = async (id) => {
  const requestObj = {
    path: `/recordings/${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const AddToFavouriteRecording = async (id, meetingId) => {
  const data = {
    user_id: String(id),
    meeting_uuid: meetingId,
  };
  const requestObj = {
    path: `/add-recording-to-favourite?user_id=${id}&meeting_uuid=${meetingId}`,
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const RemoveToFavouriteRecording = async (id, meetingId) => {
  const data = {
    user_id: String(id),
    meeting_uuid: meetingId,
  };
  const requestObj = {
    path: `/remove-recording-to-favourite?user_id=${id}&meeting_uuid=${meetingId}`,
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const DeleteRecording = async (id, meetingId) => {
  const data = {
    user_id: String(id),
    meeting_uuid: meetingId,
  };
  const requestObj = {
    path: `/delete-recording`,
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
