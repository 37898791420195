import { invokeApi } from "../../utils";

export const AllUsers = async (userId) => {
  const requestObj = {
    path: `/all-organization-users?admin_user_id=${userId}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const AddUserData = async (userId, data) => {
  const requestObj = {
    path: `/organization/add-user?admin_user_id=${userId}`,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const DeleteUserData = async (id) => {
  const requestObj = {
    path: `/users/${id}/delete`,
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const PendingUsersList = async (data) => {
  const requestObj = {
    path: `/unpaid-users-list`,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const PayByStripe = async (data) => {
  const requestObj = {
    path: `/pay-by-stripe`,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
