import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ZoomIntegration } from "../../DAL/Zoom/Zoom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, setOpen }) {
  const [userId, setUserId] = React.useState("");
  const [authUrl, setAuthUrl] = React.useState("");

  const handleIntegrate = async () => {
    const resp = await ZoomIntegration(userId);
    if (resp?.status == 200) {
      // Open the URL in a new tab
      window.open(resp?.auth_url, "_blank");
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user_data"));
    if (data) {
      setUserId(data?.user_id);
    }
  }, []);
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            className="mx-5"
            variant="contained"
            onClick={handleIntegrate}
          >
            Integrate with zoom
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
