import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserListHead from "../../components/UserListHead";
import LoaderModal from "../../components/LoaderModel";
import { PayByStripe, PendingUsersList } from "../../DAL/Users/Users";
import { useSnackbar } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { HandleStripePay, HandleStripeSuccess } from "../../DAL/Stripe/Stripe";

const TABLE_HEAD = [
  {
    id: "full_name",
    label: "Full Name",
    alignCenter: true,
  },
  { id: "email", label: "Email", alignCenter: true },
  { id: "is_paid", label: "Is Paid", alignCenter: true },
  { id: "is_zoom_signed_in", label: "Zoom Signed", alignCenter: true },
  { id: "role", label: "Role", alignCenter: true },

  // { id: "created_at", label: "Action", alignCenter: true },
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function UsersModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userLists, setUsersList] = useState([]);
  const [userId, setUserId] = useState("");
  const [openLoaderModel, setOpenLoaderModel] = useState(false);
  const [stripePaid, setStripePaid] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  const getUnPaidUsers = async (id) => {
    const data = {
      admin_user_id: id,
    };
    const resp = await PendingUsersList(data);

    if (resp.status == 200) {
      setUsersList(resp);
    }
  };

  const handleStripe = async () => {
    setOpenLoaderModel(true);
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const resp = await HandleStripePay(userData.email, userData.user_id, "user-list");
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: resp.session_id,
    });
    if (error) {
      setOpenLoaderModel(false);
      console.error("Error redirecting to Stripe Checkout:", error);
    }
  };

  const handleStripeSuccess = async (userId) => {
    const resp = await HandleStripeSuccess(userId);
    if (resp.message == "Success") {
      enqueueSnackbar("Paid successfully...", { variant: "success" });
      const userData = JSON.parse(localStorage.getItem("user_data"));
      userData.paid = true;
      setStripePaid(true);
      localStorage.setItem("user_data", JSON.stringify(userData));
      setOpenLoaderModel(false)
      navigate("/user-list");
    } else {
      navigate("/user-list");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userLists?.length) : 0;

  const isUserNotFound = userLists?.length === 0;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    const stripeSuccess = urlParams.get("stripe_success");
    const userId = urlParams.get("user_id");
    if (stripeSuccess == "true" && userId) {
      setOpenLoaderModel(true);
      handleStripeSuccess(userId);
    }
    if (stripeSuccess == "false") {
      enqueueSnackbar("Payment failed. Please try again...", {
        variant: "error",
      });
    }
    setUserId(UserData?.user_id);
    getUnPaidUsers(UserData?.user_id);
    if (userLists != []) {
      setStripePaid(true);
    }
  }, [open]);

  return (
    <div>
      <LoaderModal open={openLoaderModel} />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head d-flex justify-content-between p-2 bg-dark text-white">
            <p>Stripe Pending Users</p>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="card-main mt-2 ">
            <div className="text-end mb-2">
            {!stripePaid && (
              <Elements stripe={stripePromise}>
                <button className="active" onClick={handleStripe}>
                  Pay by stripe
                </button>
              </Elements>
            )}
            </div>
            <div className="row g-0 ">
              <Card
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 1,
                  overflowX: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} />

                    <TableBody>
                      {userLists?.map((row) => {
                        const {
                          id,
                          email,
                          full_name,
                          role,
                          is_paid,
                          is_zoom_signed_in,
                        } = row;

                        // const isItemSelected =
                        //   selected.indexOf(full_name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            // selected={isItemSelected}
                            // aria-checked={isItemSelected}
                          >
                            <TableCell align="center" sx={{ fontSize: "13px" }}>
                              {full_name}
                            </TableCell>
                            <TableCell align="center" sx={{ fontSize: "13px" }}>
                              {email}
                            </TableCell>
                            <TableCell align="center" sx={{ fontSize: "13px" }}>
                              <Chip
                                label={is_paid == true ? "True" : "False"}
                                color={is_paid == true ? "success" : "error"}
                              />
                            </TableCell>
                            <TableCell align="center" sx={{ fontSize: "13px" }}>
                              <Chip
                                label={
                                  is_zoom_signed_in == true ? "True" : "False"
                                }
                                color={
                                  is_zoom_signed_in == true
                                    ? "success"
                                    : "error"
                                }
                              />
                            </TableCell>
                            <TableCell align="center" sx={{ fontSize: "13px" }}>
                              {role}
                            </TableCell>

                            {/* <TableCell align="center">
                              <Button
                                variant="contained"
                                color="error"
                                // onClick={() => handleDelete(id)}
                              >
                                Delete
                              </Button>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                            <h5>Data not found</h5>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userLists?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
