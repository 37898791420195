import { invokeApi } from "../../utils";

export const AddInsightChatQuestion = async (data) => {
  const requestObj = {
    path: `/ask_question`,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const OldChatQuestion = async (id) => {
  const requestObj = {
    path: `/old-insight-chats?user_id=${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const DeleteOldQuestion = async (id) => {
  const requestObj = {
    path: `/delete-insight-chat?chat_uuid=${id}`,
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const GetQuestionAnswerList = async (id) => {
  const requestObj = {
    path: `/fetch-chat-qna?chat_uuid=${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
