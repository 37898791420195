import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const participants = [
  { name: "David Cooper", talking: 40, color: "#1E90FF" }, // Blue color
  { name: "Aeris Brown", talking: 14, color: "#FF7F50" }, // Coral color
  { name: "Jan Safarik", talking: 40, color: "#FF00FF" }, // Magenta color
];

const BarChart = ({ data }) => {
  return (
    <Box className="mt-5">
      <div className="row">
      <div class="col-12"> <h3> Participants List: </h3> </div>
        {data?.map((participant, index) => (
          <div className="col-6" class="pl-5 ml-5">
            <Box key={index} mb={2}>
              <Box
                display="flex"
                // justifyContent="space-between"
                gap={"10px"}
                alignItems="center"
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 35,
                    height: 35,
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "7px",
                  }}
                >
                  <PersonIcon sx={{ fontSize: 24, color: "#1a2a4b" }} />
                </span>
                <Typography className="fw-bold">{participant}</Typography>
                {/* <Typography>({participant.talking}% Talking)</Typography> */}
              </Box>
              {/* <LinearProgress
                variant="determinate"
                value={participant.talking}
                sx={{
                  height: 17, // Adjust height to match design
                  borderRadius: 1,
                  marginTop: 2,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#001f3f", // Custom color for each participant
                  },
                }}
              /> */}
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default BarChart;
